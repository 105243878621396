// Here you can add other styles
// Buttons
.btn, .btn-group {
  margin: .25rem .125rem;
}

.clickable {
  cursor: pointer;
}

.breadcrumb-link {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}
.sidebar-brand{
  background-color: #253e6a;
}
.sidebar {
  --cui-sidebar-bg: #3d699d;
}

.sidebar .nav-link {
  color: white;
}

.sidebar .nav-icon {
  color: white;
}
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.logo-image {
  width: 150px;
  height: 150px;
}
.sidebar .nav-title-white {
  color: white;
}

.my-button, .my-button:hover {
  background-color: #3d699d;
  color: white;
}

.my-button:focus {
  background-color: #5483bc;
  color: white;
}

.btn-primary {
  --cui-btn-color: #5483bc;
  --cui-btn-bg: #5483bc;
  --cui-btn-border-color: #5483bc;
  --cui-btn-hover-color: #5483bc;
  --cui-btn-hover-bg: #5483bc;
  --cui-btn-hover-border-color: #5483bc;
  --cui-btn-focus-shadow-rgb: #5483bc;
  --cui-btn-active-bg: #5483bc;
  --cui-btn-active-border-color: #5483bc;
  --cui-btn-active-shadow: #5483bc;
  --cui-btn-disabled-color: #5483bc;
  --cui-btn-disabled-bg: #5483bc;
  --cui-btn-disabled-border-color: #5483bc;
}

//Css du title des tableau
.hbotQl {
  white-space: normal !important;
}

/* TodoList.css */
:root {
  --primary-color: #2d8dfd;
  --secondary-color: #ff0000;
  --background-color: #fafafa;
  --text-color: #292929;
}

.todo-list .btn-danger .MuiSvgIcon-root {
  color: white;
}

.todo-list {
  font-family: 'Open Sans', sans-serif;
  color: var(--text-color);
}

.todo-list input[type='text'],
.todo-list input[type='number'] {
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid #5483bc;
}

// .todo-list ul {
//   list-style-type: none;
// }

// .todo-list li {
//   margin-bottom: 10px;
// }

.todo-list button {
  margin-left: 10px;
}

.todo-list .btn-danger {
  background-color: var(--secondary-color);
}

.border-right {
  border-right: 1px solid black;
}

.padding-5 {
  padding: 5px;
}

.table-container {
  overflow-x: auto;
  max-width: 100%;

}

.table-container table {
  width: 100%;
}

@media (max-width: 767px) {
  .table-container {
    max-width: 100%;
  }
}

.hover-icon:hover {
  opacity: 0.7;
}

tr:not(:last-child) td:not(.no-border-bottom) {
  border-bottom: 1px solid gray;
}

.custom-border-top {
  border-top: 10px solid #6e7881;
  padding: 0;
}

tr:not(:first-child):not([style*="border-top"]) {
  background-color: white;
}


table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

th {
  background-color: #6e7881;
  color: white;
}

th:nth-child(1), td:nth-child(1) {
  width: 10%;
}

th:nth-child(2), td:nth-child(2) {
  width: 50%;
}

th:nth-child(3), td:nth-child(3) {
  width: 10%;
}

th:nth-child(4), td:nth-child(4) {
  width: 15%;
}

th:nth-child(5), td:nth-child(5) {
  width: 25%;
}
td:nth-child(2) {
  word-wrap: break-word;
  white-space: pre-wrap;
}

td {
  word-wrap: break-word;
}

input[type="text"].todo-input, input[type="number"].todo-input {
  width: 100%;
  box-sizing: border-box;
}

.todo-title {
  font-weight: bold;
  font-size: 18px;
}

/*Leaflet*/
.leaflet-container{
  height: 100vh;
}

//gallery 
.image-gallery-image img{
  object-fit: contain;
  width: 100%;
  height: 500px;
}

.image-gallery-thumbnail-inner img{
  object-fit: contain;
  width: 100%;
  height: 100px;
}

//Dropdown
.my-dropdown {
  background-color: #2d8dfd;
  color: white;
}

.my-dropdown:hover {
  background-color: #1c6cbf; /* Changez cette couleur en fonction de vos préférences */
  color: white;
}

/* Ajoutez ceci à votre fichier CSS */
@media screen and (max-width: 600px) {
  body {
    font-size: 0.8em; /* Ajustez cette valeur selon vos besoins */
  }
  .my-dropdown {
    font-size: 0.8em; // Adjust this value as needed
  }
}

//Demande stock
.my-custom-table th {
  background-color: #6e7881;
  color: white;
}

//Swal react content
.my-custom-CFormSelect {
  width: calc(100% - 2 * 42px);
  height: 2.625em;
  padding: 0 0.75em;
  box-sizing: border-box;
  transition: border-color .1s,box-shadow .1s;
  border: 1px solid #d9d9d9;
  border-radius: 0.1875em;
  background: rgba(0,0,0,0);
  color: inherit;
  font-size: 1.125em;
  margin: 1em 2em 3px;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23636f83%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right .75em top 50%, 0; /* adjust as needed */
  background-size: 16px 12px;
}

.my-custom-CFormSelect:focus {
  box-shadow: inset 0 1px 1px rgba(0,0,0,.06), 0 0 0 3px rgba(50,31,219,.25);
}


//Swal 

.confirm-button-class {
  box-shadow: 0 0 10px red !important;
}

//Onglet
.custom-onglet-one {
  background-color: rgb(177, 192, 192) !important;
  color:#ffffff;
}
// .custom-onglet-one:focus {
//   color:#ffffff !important;
// }

.custom-onglet-two {
  background-color: rgb(38, 196, 236) !important;
  color:#ffffff;
}

.custom-onglet-three {
  background-color: #5483bc !important;
  color:#ffffff;
}

.custom-onglet-three.active {
  background-color: #5483bc !important;
  color: #dedbdb !important;
}

// color:#253e6a;

.apexcharts-svg {
  overflow: visible;
}

.chart-container {
  padding: 2rem;
  padding-right: 2.7rem;
}
